import React from 'react';

import { Link } from 'gatsby';

import './index.css';
import { SideMainSvg } from './PageSvgs';

const NotFoundIndex = ({ blog }) => {
  return (
    <section className="not-found root-padding">
      <div>
        <p className="heading">404 Error:</p>
        <p className="sub-heading">
          We’ll get one of our developers on this right away.
        </p>
        <div className="blog-container">
          <p className="recommended-blog">
            In the meantime, you may enjoy this:
          </p>
          <p className="recommended-blog">
            <Link to={blog.slug}>{blog.title}</Link>
          </p>
        </div>
        <Link to="/" className="go-home-btn">
          Or take me home
        </Link>
      </div>
      <div className="svg-container">
        <SideMainSvg />
      </div>
    </section>
  );
};

export default NotFoundIndex;
