import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Import sections
import Body from '../../components/404/index';

const NotFoundPageTemplate = ({blog}) => {
  return (
    <main className="root-container">
      <Body blog={blog}/>
    </main>
  );
};

const NotFound = ({ data }) => {
  const { frontmatter, fields } = data.allMarkdownRemark.edges[0].node;
  const blog = {...frontmatter, ...fields}
  return <NotFoundPageTemplate blog={blog}/>;
};

NotFound.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default NotFound;

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "404/index" } }) {
      frontmatter {
        templateKey
      }
    }
    allMarkdownRemark(
      filter: { published: { eq: true }, frontmatter: { templateKey: { eq: "Blog/index" } } }
      limit: 1
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
