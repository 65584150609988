import React from 'react';

export const SideMainSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="590.328"
      height="344.529"
      viewBox="0 0 590.328 344.529"
    >
      <g
        id="Group_7805"
        data-name="Group 7805"
        transform="translate(-593.047 -229.833)"
      >
        <path
          id="Path_2184"
          data-name="Path 2184"
          d="M191.832,107.843l4.766,4.37a27.208,27.208,0,0,1,1.8,38.371l-32.955,36.357a27.029,27.029,0,0,1-38.266,1.809L94.977,159.357"
          transform="translate(564.185 242.934)"
          fill="#f6e049"
        />
        <path
          id="Path_2185"
          data-name="Path 2185"
          d="M144.615,196.76a28.477,28.477,0,0,1-19.337-7.51L93.073,159.857a1.713,1.713,0,0,1,2.315-2.526l32.206,29.393a25.377,25.377,0,0,0,35.882-1.707l32.955-36.357a25.556,25.556,0,0,0-1.7-35.982l-4.766-4.37a1.713,1.713,0,0,1,2.315-2.526l4.766,4.37a29,29,0,0,1,1.941,40.795L166.029,187.3a28.472,28.472,0,0,1-19.95,9.388C145.568,196.726,145.092,196.76,144.615,196.76Z"
          transform="translate(564.932 243.697)"
          fill="#383a48"
        />
        <path
          id="Path_2186"
          data-name="Path 2186"
          d="M124.6,183.4c4.8-5.291,12.767-5.872,17.771-1.331L195.89,230.89c5,4.574,5.175,12.563.374,17.854s-12.767,5.872-17.771,1.331l-53.517-48.817C119.972,196.717,119.8,188.729,124.6,183.4Z"
          transform="translate(556.246 221.312)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2187"
          data-name="Path 2187"
          d="M196.343,236.119l-26.282-23.965a1.685,1.685,0,0,0-2.315.034h0a1.716,1.716,0,0,0,.034,2.492l26.282,23.965a10.236,10.236,0,0,1,3.3,7.442A11.746,11.746,0,0,1,186.641,257.9a10.253,10.253,0,0,1-7.694-2.594l-53.517-48.817a10.216,10.216,0,0,1-1.021-1.092l-5.617-5.121a13.657,13.657,0,0,0,4.358,8.739l53.517,48.817a13.476,13.476,0,0,0,9.158,3.516c.34,0,.715,0,1.055-.034a14.824,14.824,0,0,0,9.941-4.916,15.055,15.055,0,0,0,3.949-10.378A13.633,13.633,0,0,0,196.343,236.119Z"
          transform="translate(556.95 214.853)"
          fill="#383a48"
        />
        <path
          id="Path_2188"
          data-name="Path 2188"
          d="M165.484,170.363c4.8-5.291,12.767-5.872,17.805-1.3l53.653,48.954c5.039,4.574,5.209,12.6.409,17.888s-12.767,5.872-17.805,1.3l-53.653-48.954C160.854,183.676,160.684,175.688,165.484,170.363Z"
          transform="translate(543.824 225.271)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2189"
          data-name="Path 2189"
          d="M250.468,232.438a13.485,13.485,0,0,0-4.358-8.569L208.7,189.731a1.715,1.715,0,0,0-2.417.1h0a1.726,1.726,0,0,0,.1,2.424l37.483,34.172a10.259,10.259,0,0,1,3.268,7.408,11.574,11.574,0,0,1-3.03,8.022c-4.153,4.609-11.064,5.121-15.388,1.195L191.2,208.814a1.685,1.685,0,0,0-2.315.034h0a1.716,1.716,0,0,0,.034,2.492l37.517,34.206a13.606,13.606,0,0,0,9.192,3.516A15.1,15.1,0,0,0,250.536,233.7C250.536,233.291,250.5,232.882,250.468,232.438Z"
          transform="translate(535.814 218.192)"
          fill="#383a48"
        />
        <path
          id="Path_2190"
          data-name="Path 2190"
          d="M190.937,142.072c5-5.53,13.175-6.316,18.18-1.707l61.858,56.43c5.039,4.574,5.039,12.768,0,18.3-5,5.53-13.175,6.316-18.18,1.707l-61.858-56.4C185.933,155.8,185.9,147.6,190.937,142.072Z"
          transform="translate(536.176 233.967)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2191"
          data-name="Path 2191"
          d="M282.451,205.43a13.516,13.516,0,0,0-2.349-2.868l-3.881-3.55-41.738-38.064a1.715,1.715,0,0,0-2.417.1h0a1.726,1.726,0,0,0,.1,2.424l41.738,38.064,3.881,3.55a10.01,10.01,0,0,1,3.2,7.545,12.588,12.588,0,0,1-3.3,8.364c-4.392,4.813-11.473,5.53-15.8,1.6L216.27,180.987a1.715,1.715,0,0,0-2.417.1h0a1.726,1.726,0,0,0,.1,2.424l37.517,34.172,6.6,6.008,1.566,1.434a13.188,13.188,0,0,0,9.022,3.414,16.149,16.149,0,0,0,15.8-15.908A13.864,13.864,0,0,0,282.451,205.43Z"
          transform="translate(528.206 226.937)"
          fill="#383a48"
        />
        <path
          id="Path_2192"
          data-name="Path 2192"
          d="M295.757,179.043a13.609,13.609,0,0,1-21.652,4.609l-43-39.225-2.894-2.629L214.7,129.44a13.65,13.65,0,0,1-3.677-14.611,13.95,13.95,0,0,1,6.23-7.442,11.889,11.889,0,0,1,4.085-1.434,13.6,13.6,0,0,1,2.349-.171,14.075,14.075,0,0,1,9.192,3.346l29.55,26.969,30.1,27.447C296.506,167.2,298,174.161,295.757,179.043Z"
          transform="translate(529.165 243.56)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2193"
          data-name="Path 2193"
          d="M297.988,161.586,238.309,107.17a15.86,15.86,0,0,0-10.315-3.789,17.393,17.393,0,0,0-2.655.2c-.34.068-.715.137-1.021.2.885.99,1.77,1.98,2.621,3.038a7.971,7.971,0,0,1,.885-.034h.136a12.225,12.225,0,0,1,8.034,2.9l59.679,54.45c3.438,3.141,4.732,9.32,2.826,13.45a12.229,12.229,0,0,1-8.613,6.964,11.9,11.9,0,0,1-10.383-2.868l-41.738-38.064a1.715,1.715,0,0,0-2.417.1h0a1.726,1.726,0,0,0,.1,2.424l41.738,38.064a15.317,15.317,0,0,0,10.315,3.994,14.873,14.873,0,0,0,3-.307,15.611,15.611,0,0,0,11.03-8.842h0C304.116,173.534,302.516,165.717,297.988,161.586Z"
          transform="translate(524.891 244.289)"
          fill="#383a48"
        />
        <path
          id="Path_2194"
          data-name="Path 2194"
          d="M194.73,128.011,157.554,168.6c-9.532,10.515-25.737,11.061-36.529,1.195l-21.55-19.664c-20.563-18.776-22.537-50.285-4.392-70.324,18.146-20,29.789-26.457,54.437-10.719l25.329,15.055C185.641,93.975,204.263,117.5,194.73,128.011Z"
          transform="translate(567.925 257.382)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2195"
          data-name="Path 2195"
          d="M56.257,118.751l-36.6-30.895a48.555,48.555,0,0,1-6.6-67.286h0a48.214,48.214,0,0,1,67.884-7.579L115.63,40.575"
          transform="translate(592.283 274.972)"
          fill="#eeeae9"
        />
        <path
          id="Path_2196"
          data-name="Path 2196"
          d="M55.5,119.732a1.666,1.666,0,0,1-1.089-.41L17.783,88.462A50.085,50.085,0,0,1,81.242,10.969l34.691,27.584a1.7,1.7,0,0,1,.272,2.39,1.715,1.715,0,0,1-2.383.273L79.131,13.631a46.666,46.666,0,0,0-59.137,72.2l36.6,30.892a1.708,1.708,0,0,1-1.089,3Z"
          transform="translate(593.035 275.696)"
          fill="#383a48"
        />
        <g
          id="Component_25_1"
          data-name="Component 25 – 1"
          transform="translate(692.63 279.157)"
        >
          <g
            id="Group_7801"
            data-name="Group 7801"
            transform="matrix(0.985, 0.174, -0.174, 0.985, -635.812, -433.418)"
          >
            <path
              id="Path_2199"
              data-name="Path 2199"
              d="M2.77,111.947,154.218,30.283a2.744,2.744,0,0,1,3.3.542,1.719,1.719,0,0,1-.574,2.631L9.893,120.226a7.226,7.226,0,0,1-8.7-1.122c-2.02-2.254-1.4-5.406,1.386-7.041C2.627,112.025,2.722,111.986,2.77,111.947Z"
              transform="translate(721.138 307.124)"
              fill="#3ee5b8"
              stroke="#383a48"
              stroke-width="3"
            />
            <path
              id="Path_2200"
              className="animated-dot animated-dot-1"
              data-name="Path 2200"
              d="M172.4,11.356a1.956,1.956,0,0,1-1.937-1.663l-1.007-7.5a1.934,1.934,0,0,1,3.835-.5l1.007,7.5a1.925,1.925,0,0,1-1.666,2.166Z"
              transform="matrix(0.978, 0.208, -0.208, 0.978, 705.005, 277.714)"
              fill="#faff8e"
            />
            <path
              id="Path_2201"
              data-name="Path 2201"
              className="animated-dot  animated-dot-2"
              d="M187.48,11.567a1.969,1.969,0,0,1-.658-.116,1.937,1.937,0,0,1-1.162-2.476l2.556-7.118A1.91,1.91,0,0,1,190.694.7a1.937,1.937,0,0,1,1.162,2.476L189.3,10.291a1.921,1.921,0,0,1-1.82,1.277Z"
              transform="matrix(0.978, 0.208, -0.208, 0.978, 705.458, 277.633)"
              fill="#faff8e"
            />
            <path
              id="Path_2202"
              data-name="Path 2202"
              className="animated-dot animated-dot-3"
              d="M199.28,24.954a1.922,1.922,0,0,1-.7-3.714l7.011-2.824a1.936,1.936,0,1,1,1.433,3.6l-7.011,2.824A2.637,2.637,0,0,1,199.28,24.954Z"
              transform="matrix(0.978, 0.208, -0.208, 0.978, 703.065, 271.626)"
              fill="#faff8e"
            />
            <path
              id="Path_2203"
              data-name="Path 2203"
              className="animated-dot animated-dot-4"
              d="M204.535,47.847a1.736,1.736,0,0,1-.775-.155l-6.933-3.056a1.941,1.941,0,1,1,1.549-3.559l6.933,3.056a1.959,1.959,0,0,1,1.007,2.553A2.044,2.044,0,0,1,204.535,47.847Z"
              transform="matrix(0.978, 0.208, -0.208, 0.978, 704.756, 266.153)"
              fill="#faff8e"
            />
          </g>
        </g>
        <path
          id="Path_2197"
          data-name="Path 2197"
          d="M111.294,166.842l-52.9-44.926C38.372,104.984,38,74.43,54.338,53.913h0a48.214,48.214,0,0,1,67.884-7.579l14.571,11.334a25.614,25.614,0,0,0,8.239,4.267L199.4,78.22c11.339,3.3,20.363,9.44,17.93,21.353-2.127,10.412-13.432,13.412-26.475,8.825l-29.278-8.739c-12.29-3.755-36.019,24.545-55.9-4.813"
          transform="translate(580.073 264.842)"
          fill="#e9f7fa"
        />
        <path
          id="Path_2198"
          data-name="Path 2198"
          d="M110.553,167.824a1.666,1.666,0,0,1-1.089-.41l-52.9-44.926A46.517,46.517,0,0,1,40.286,88.624,54.774,54.774,0,0,1,52.27,52.13a49.958,49.958,0,0,1,70.267-7.818l14.537,11.334a22.93,22.93,0,0,0,7.694,3.96L199.136,75.89c1.7.478,21.9,5.291,19.184,23.329-3.507,19.4-27.185,10.6-28.717,10.161l-29.278-8.739c-3.438-1.058-8.341,1.024-14.026,3.414-5.822,2.424-12.664,5.326-19.78,5.326-7.694,0-15.66-3.346-23.014-14.2a1.667,1.667,0,0,1,.443-2.356,1.686,1.686,0,0,1,2.349.444c12.6,18.605,26.452,12.768,38.64,7.647,6.3-2.629,11.745-4.916,16.341-3.516l29.244,8.739c1.668.478,21.37,6.661,24.177-8.052,2.289-14.309-15.189-18.473-16.585-18.883l-54.3-16.352a26.593,26.593,0,0,1-8.783-4.54L120.46,46.975a46.547,46.547,0,0,0-65.535,7.271A51.316,51.316,0,0,0,43.657,88.453,42.926,42.926,0,0,0,58.738,119.86l52.9,44.926a1.69,1.69,0,0,1,.2,2.39,1.609,1.609,0,0,1-1.294.649Z"
          transform="translate(580.813 265.566)"
          fill="#383a48"
        />
        <line
          id="Line_218"
          data-name="Line 218"
          x2="1.007"
          y2="7.505"
          transform="translate(841.056 313.813)"
          fill="none"
        />
        <line
          id="Line_219"
          data-name="Line 219"
          x1="2.556"
          y2="7.118"
          transform="translate(852.25 314.239)"
          fill="none"
        />
        <line
          id="Line_220"
          data-name="Line 220"
          x1="7.011"
          y2="2.863"
          transform="translate(860.461 326.502)"
          fill="none"
        />
        <line
          id="Line_221"
          data-name="Line 221"
          x1="6.933"
          y1="3.056"
          transform="translate(859.299 342.324)"
          fill="none"
        />
        <ellipse
          id="Ellipse_4385"
          data-name="Ellipse 4385"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4386"
          data-name="Ellipse 4386"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4387"
          data-name="Ellipse 4387"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4388"
          data-name="Ellipse 4388"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4389"
          data-name="Ellipse 4389"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4390"
          data-name="Ellipse 4390"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4391"
          data-name="Ellipse 4391"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4392"
          data-name="Ellipse 4392"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4393"
          data-name="Ellipse 4393"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4394"
          data-name="Ellipse 4394"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4395"
          data-name="Ellipse 4395"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4396"
          data-name="Ellipse 4396"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4397"
          data-name="Ellipse 4397"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4398"
          data-name="Ellipse 4398"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4399"
          data-name="Ellipse 4399"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4400"
          data-name="Ellipse 4400"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 549.884)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4401"
          data-name="Ellipse 4401"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 558.448)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4402"
          data-name="Ellipse 4402"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(931.161 566.978)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4403"
          data-name="Ellipse 4403"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4404"
          data-name="Ellipse 4404"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4405"
          data-name="Ellipse 4405"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4406"
          data-name="Ellipse 4406"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4407"
          data-name="Ellipse 4407"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4408"
          data-name="Ellipse 4408"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4409"
          data-name="Ellipse 4409"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4410"
          data-name="Ellipse 4410"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4411"
          data-name="Ellipse 4411"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4412"
          data-name="Ellipse 4412"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4413"
          data-name="Ellipse 4413"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4414"
          data-name="Ellipse 4414"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4415"
          data-name="Ellipse 4415"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4416"
          data-name="Ellipse 4416"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4417"
          data-name="Ellipse 4417"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4418"
          data-name="Ellipse 4418"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 549.884)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4419"
          data-name="Ellipse 4419"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 558.448)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4420"
          data-name="Ellipse 4420"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(922.61 566.978)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4421"
          data-name="Ellipse 4421"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4422"
          data-name="Ellipse 4422"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4423"
          data-name="Ellipse 4423"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4424"
          data-name="Ellipse 4424"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4425"
          data-name="Ellipse 4425"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4426"
          data-name="Ellipse 4426"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4427"
          data-name="Ellipse 4427"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4428"
          data-name="Ellipse 4428"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4429"
          data-name="Ellipse 4429"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4430"
          data-name="Ellipse 4430"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4431"
          data-name="Ellipse 4431"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4432"
          data-name="Ellipse 4432"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4433"
          data-name="Ellipse 4433"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4434"
          data-name="Ellipse 4434"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4435"
          data-name="Ellipse 4435"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4436"
          data-name="Ellipse 4436"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 549.884)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4437"
          data-name="Ellipse 4437"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 558.448)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4438"
          data-name="Ellipse 4438"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(914.061 566.978)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4439"
          data-name="Ellipse 4439"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4440"
          data-name="Ellipse 4440"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4441"
          data-name="Ellipse 4441"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4442"
          data-name="Ellipse 4442"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4443"
          data-name="Ellipse 4443"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4444"
          data-name="Ellipse 4444"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4445"
          data-name="Ellipse 4445"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4446"
          data-name="Ellipse 4446"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4447"
          data-name="Ellipse 4447"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4448"
          data-name="Ellipse 4448"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4449"
          data-name="Ellipse 4449"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4450"
          data-name="Ellipse 4450"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4451"
          data-name="Ellipse 4451"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4452"
          data-name="Ellipse 4452"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4453"
          data-name="Ellipse 4453"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4454"
          data-name="Ellipse 4454"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 549.884)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4455"
          data-name="Ellipse 4455"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 558.448)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4456"
          data-name="Ellipse 4456"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(905.544 566.978)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4457"
          data-name="Ellipse 4457"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4458"
          data-name="Ellipse 4458"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4459"
          data-name="Ellipse 4459"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4460"
          data-name="Ellipse 4460"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4461"
          data-name="Ellipse 4461"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4462"
          data-name="Ellipse 4462"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4463"
          data-name="Ellipse 4463"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4464"
          data-name="Ellipse 4464"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4465"
          data-name="Ellipse 4465"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4466"
          data-name="Ellipse 4466"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4467"
          data-name="Ellipse 4467"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4468"
          data-name="Ellipse 4468"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4469"
          data-name="Ellipse 4469"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4470"
          data-name="Ellipse 4470"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4471"
          data-name="Ellipse 4471"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4472"
          data-name="Ellipse 4472"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 549.884)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4473"
          data-name="Ellipse 4473"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(896.993 558.448)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4474"
          data-name="Ellipse 4474"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4475"
          data-name="Ellipse 4475"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4476"
          data-name="Ellipse 4476"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4477"
          data-name="Ellipse 4477"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4478"
          data-name="Ellipse 4478"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4479"
          data-name="Ellipse 4479"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4480"
          data-name="Ellipse 4480"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4481"
          data-name="Ellipse 4481"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 481.477)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4482"
          data-name="Ellipse 4482"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 490.006)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4483"
          data-name="Ellipse 4483"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 498.569)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4484"
          data-name="Ellipse 4484"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 507.134)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4485"
          data-name="Ellipse 4485"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 515.663)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4486"
          data-name="Ellipse 4486"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 524.227)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4487"
          data-name="Ellipse 4487"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 532.791)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4488"
          data-name="Ellipse 4488"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(888.443 541.32)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4489"
          data-name="Ellipse 4489"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4490"
          data-name="Ellipse 4490"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4491"
          data-name="Ellipse 4491"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4492"
          data-name="Ellipse 4492"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4493"
          data-name="Ellipse 4493"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4494"
          data-name="Ellipse 4494"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4495"
          data-name="Ellipse 4495"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(879.927 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4496"
          data-name="Ellipse 4496"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4497"
          data-name="Ellipse 4497"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4498"
          data-name="Ellipse 4498"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4499"
          data-name="Ellipse 4499"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4500"
          data-name="Ellipse 4500"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4501"
          data-name="Ellipse 4501"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4502"
          data-name="Ellipse 4502"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(871.377 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4503"
          data-name="Ellipse 4503"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 421.598)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4504"
          data-name="Ellipse 4504"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4505"
          data-name="Ellipse 4505"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4506"
          data-name="Ellipse 4506"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4507"
          data-name="Ellipse 4507"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4508"
          data-name="Ellipse 4508"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4509"
          data-name="Ellipse 4509"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(862.826 472.912)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4510"
          data-name="Ellipse 4510"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(854.31 430.161)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4511"
          data-name="Ellipse 4511"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(854.31 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4512"
          data-name="Ellipse 4512"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(854.31 447.255)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4513"
          data-name="Ellipse 4513"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(854.31 455.818)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4514"
          data-name="Ellipse 4514"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(854.31 464.348)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4515"
          data-name="Ellipse 4515"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(845.76 438.69)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4516"
          data-name="Ellipse 4516"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(845.76 447.255)"
          fill="#383a48"
        />
        <path
          id="Path_2204"
          data-name="Path 2204"
          d="M246.16,180.013H91.592v90.425a33.121,33.121,0,0,0,33.095,33.146h88.379a33.121,33.121,0,0,0,33.095-33.146Z"
          transform="translate(817.934 269.044)"
          fill="#ff9234"
        />
        <path
          id="Path_2205"
          data-name="Path 2205"
          d="M212.31,304.562H123.931a34.879,34.879,0,0,1-34.825-34.88V179.257a1.715,1.715,0,0,1,1.731-1.734H245.4a1.715,1.715,0,0,1,1.731,1.734v90.425A34.879,34.879,0,0,1,212.31,304.562ZM92.567,180.99v88.691a31.427,31.427,0,0,0,31.364,31.413H212.31a31.427,31.427,0,0,0,31.364-31.413V180.99Z"
          transform="translate(818.689 269.801)"
          fill="#383a48"
        />
        <path
          id="Path_2224"
          data-name="Path 2224"
          d="M0,0H154.568V47.05H0Z"
          transform="translate(909.525 468.891)"
          fill="#ea6161"
        />
        <path
          id="Path_2206"
          data-name="Path 2206"
          d="M245.4,256.562H90.836a1.715,1.715,0,0,1-1.731-1.734V207.744a1.715,1.715,0,0,1,1.731-1.734H245.4a1.715,1.715,0,0,1,1.731,1.734v47.05a1.745,1.745,0,0,1-1.731,1.768ZM92.567,253.095H243.674V209.477H92.567Z"
          transform="translate(818.689 261.146)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4517"
          data-name="Ellipse 4517"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1058.209 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4518"
          data-name="Ellipse 4518"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1058.209 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4519"
          data-name="Ellipse 4519"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1058.209 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4520"
          data-name="Ellipse 4520"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1058.209 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4521"
          data-name="Ellipse 4521"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1049.658 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4522"
          data-name="Ellipse 4522"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1049.658 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4523"
          data-name="Ellipse 4523"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1049.658 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4524"
          data-name="Ellipse 4524"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1049.658 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4525"
          data-name="Ellipse 4525"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1041.107 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4526"
          data-name="Ellipse 4526"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1041.107 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4527"
          data-name="Ellipse 4527"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1041.107 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4528"
          data-name="Ellipse 4528"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1041.107 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4529"
          data-name="Ellipse 4529"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1032.592 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4530"
          data-name="Ellipse 4530"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1032.592 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4531"
          data-name="Ellipse 4531"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1032.592 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4532"
          data-name="Ellipse 4532"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1032.592 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4533"
          data-name="Ellipse 4533"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1024.041 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4534"
          data-name="Ellipse 4534"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1024.041 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4535"
          data-name="Ellipse 4535"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1024.041 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4536"
          data-name="Ellipse 4536"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1024.041 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4537"
          data-name="Ellipse 4537"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1015.505 482.152)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4538"
          data-name="Ellipse 4538"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1015.505 490.682)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4539"
          data-name="Ellipse 4539"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1015.505 499.245)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4540"
          data-name="Ellipse 4540"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1015.506 507.809)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4541"
          data-name="Ellipse 4541"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1006.975 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4542"
          data-name="Ellipse 4542"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1006.975 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4543"
          data-name="Ellipse 4543"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1006.975 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4544"
          data-name="Ellipse 4544"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(1006.975 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4545"
          data-name="Ellipse 4545"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(998.424 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4546"
          data-name="Ellipse 4546"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(998.424 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4547"
          data-name="Ellipse 4547"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(998.424 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4548"
          data-name="Ellipse 4548"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(998.424 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4549"
          data-name="Ellipse 4549"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(989.873 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4550"
          data-name="Ellipse 4550"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(989.873 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4551"
          data-name="Ellipse 4551"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(989.873 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4552"
          data-name="Ellipse 4552"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(989.873 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4553"
          data-name="Ellipse 4553"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(981.357 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4554"
          data-name="Ellipse 4554"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(981.357 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4555"
          data-name="Ellipse 4555"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(981.357 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4556"
          data-name="Ellipse 4556"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(981.357 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4557"
          data-name="Ellipse 4557"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(972.807 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4558"
          data-name="Ellipse 4558"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(972.807 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4559"
          data-name="Ellipse 4559"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(972.807 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4560"
          data-name="Ellipse 4560"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(972.807 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4561"
          data-name="Ellipse 4561"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(964.256 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4562"
          data-name="Ellipse 4562"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(964.256 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4563"
          data-name="Ellipse 4563"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(964.256 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4564"
          data-name="Ellipse 4564"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(964.256 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4565"
          data-name="Ellipse 4565"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(955.74 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4566"
          data-name="Ellipse 4566"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(955.74 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4567"
          data-name="Ellipse 4567"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(955.74 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4568"
          data-name="Ellipse 4568"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(955.74 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4569"
          data-name="Ellipse 4569"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(947.189 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4570"
          data-name="Ellipse 4570"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(947.189 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4571"
          data-name="Ellipse 4571"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(947.189 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4572"
          data-name="Ellipse 4572"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(947.189 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4573"
          data-name="Ellipse 4573"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(938.639 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4574"
          data-name="Ellipse 4574"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(938.639 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4575"
          data-name="Ellipse 4575"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(938.639 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4576"
          data-name="Ellipse 4576"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(938.639 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4577"
          data-name="Ellipse 4577"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(930.123 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4578"
          data-name="Ellipse 4578"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(930.123 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4579"
          data-name="Ellipse 4579"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(930.123 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4580"
          data-name="Ellipse 4580"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(930.123 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4581"
          data-name="Ellipse 4581"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(921.572 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4582"
          data-name="Ellipse 4582"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(921.572 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4583"
          data-name="Ellipse 4583"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(921.572 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4584"
          data-name="Ellipse 4584"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(921.572 507.827)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4585"
          data-name="Ellipse 4585"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(913.021 482.17)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4586"
          data-name="Ellipse 4586"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(913.021 490.699)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4587"
          data-name="Ellipse 4587"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(913.021 499.263)"
          fill="#383a48"
        />
        <ellipse
          id="Ellipse_4588"
          data-name="Ellipse 4588"
          cx="1.731"
          cy="1.734"
          rx="1.731"
          ry="1.734"
          transform="translate(913.021 507.827)"
          fill="#383a48"
        />
        <path
          id="Path_2207"
          data-name="Path 2207"
          d="M239.761,194.838H60.406a30.71,30.71,0,0,1-30.671-30.719h0A30.71,30.71,0,0,1,60.406,133.4H239.8a30.71,30.71,0,0,1,30.671,30.719h0A30.739,30.739,0,0,1,239.761,194.838Z"
          transform="translate(836.726 283.206)"
          fill="#ff9234"
        />
        <path
          id="Path_2208"
          data-name="Path 2208"
          d="M239.006,195.815H59.651a32.453,32.453,0,0,1,0-64.906H239.04a32.453,32.453,0,0,1-.035,64.906ZM59.651,134.376a28.986,28.986,0,0,0,0,57.972H239.04a28.986,28.986,0,0,0,0-57.972Z"
          transform="translate(837.481 283.963)"
          fill="#383a48"
        />
        <path
          id="Path_2209"
          data-name="Path 2209"
          d="M236.231,161.836H100.01a10.922,10.922,0,0,0,0,21.843H236.265a10.922,10.922,0,0,0-.035-21.843Z"
          transform="translate(818.689 274.566)"
          fill="#383a48"
        />
        <path
          id="Path_2210"
          data-name="Path 2210"
          d="M112.942,4.293c31.433-14.84,43.272,67.506,47.807,129.153H127.031S79.5,20.068,112.942,4.293Z"
          transform="translate(815.069 322.962)"
          fill="#faff8e"
        />
        <path
          id="Path_2211"
          data-name="Path 2211"
          d="M159.986,134.409H126.268a1.726,1.726,0,0,1-1.592-1.075,486.519,486.519,0,0,1-19.663-58.908C94.558,33.271,96.7,8.9,111.451,1.927h0c5.643-2.67,11.043-2.566,15.959.312,5.262,3.086,10.039,9.431,14.228,18.9,3.635,8.217,6.924,19,9.762,32.072,4.535,20.8,8,47.5,10.351,79.295a1.8,1.8,0,0,1-.45,1.318A1.845,1.845,0,0,1,159.986,134.409Zm-32.541-3.467h30.706c-2.319-30.823-5.712-56.723-10.143-76.972-5.92-27.079-13.432-43.479-22.363-48.68-3.946-2.323-8.1-2.358-12.739-.173-8.931,4.23-17,19.52-4.535,68.477A479.585,479.585,0,0,0,127.445,130.942ZM112.178,3.522Z"
          transform="translate(815.833 323.732)"
          fill="#383a48"
        />
        <path
          id="Path_2212"
          data-name="Path 2212"
          d="M266.195,4.293c-31.433-14.84-43.272,67.506-47.807,129.153h33.718S299.636,20.068,266.195,4.293Z"
          transform="translate(779.413 322.962)"
          fill="#faff8e"
        />
        <path
          id="Path_2213"
          data-name="Path 2213"
          d="M251.347,134.424H217.63a1.723,1.723,0,0,1-1.731-1.872c2.319-31.829,5.816-58.492,10.351-79.3,2.839-13.071,6.127-23.854,9.762-32.072,4.189-9.465,8.966-15.81,14.228-18.9,4.95-2.878,10.316-2.982,15.959-.312,14.713,6.934,16.893,31.344,6.439,72.5a486.518,486.518,0,0,1-19.663,58.908A1.838,1.838,0,0,1,251.347,134.424ZM251.347,132.69ZM219.5,130.957h30.706a478.6,478.6,0,0,0,19.074-57.382c12.428-48.957,4.4-64.247-4.57-68.443-4.639-2.184-8.793-2.115-12.739.173-8.931,5.235-16.443,21.6-22.328,48.714-4.431,20.214-7.824,46.114-10.143,76.937Z"
          transform="translate(780.171 323.718)"
          fill="#383a48"
        />
        <path
          id="Path_2214"
          data-name="Path 2214"
          d="M236.537,154.411l18.14-81.237a1.83,1.83,0,0,1,1.627-1.63h0c1.073,0,1.869,1.318,1.662,2.739l-12.22,80.127"
          transform="translate(773.898 301.997)"
          fill="#3ee5b8"
        />
        <path
          id="Path_2215"
          data-name="Path 2215"
          d="M159.3,154.411l-18.14-81.237a1.83,1.83,0,0,0-1.627-1.63h0c-1.073,0-1.869,1.318-1.662,2.739l12.22,80.127"
          transform="translate(803.884 301.997)"
          fill="#3ee5b8"
        />
        <path
          id="Path_2216"
          data-name="Path 2216"
          d="M229.7,191.548H122.66a1.734,1.734,0,0,1,0-3.467H229.7a1.734,1.734,0,0,1,0,3.467Z"
          transform="translate(809.021 266.594)"
          fill="#383a48"
        />
        <g id="Layer_1_1_-i10" transform="translate(1087.341 262.484)">
          <g id="Group_7776" data-name="Group 7776">
            <g id="Group_7775" data-name="Group 7775">
              <path
                id="Path_2221"
                data-name="Path 2221"
                d="M1.769,71.019A1.774,1.774,0,0,1,0,69.25a1.752,1.752,0,0,1,1.769-1.769c12.239-.106,28.971-3.361,39.406-18.222a43.19,43.19,0,0,0,7.181-17.443A41.779,41.779,0,0,1,35.8,20.777c-2.724-3.644-3.254-7.572-1.486-10.792a8.647,8.647,0,0,1,8.207-4.352c3.714.318,6.721,2.972,8.242,7.289A38.878,38.878,0,0,1,52.176,29.87,38.908,38.908,0,0,0,57.8,32.134c7.358,2.264,14.751,2.123,20.835-.389a21.29,21.29,0,0,0,11-10.084c2.688-5.272,3.5-11.853,2.405-19.637a1.766,1.766,0,1,1,3.5-.46c2.264,16.24-3.4,28.447-15.529,33.472-6.863,2.831-15.1,3.007-23.241.5A37.932,37.932,0,0,1,51.61,33.55a46.848,46.848,0,0,1-7.535,17.8c-6.261,8.846-18.642,19.46-42.307,19.672ZM41.812,9.136a5.1,5.1,0,0,0-4.422,2.583c-1.1,1.981-.672,4.458,1.2,6.97A38.413,38.413,0,0,0,48.816,28.03,34.8,34.8,0,0,0,47.4,14.089c-1.026-2.972-2.865-4.706-5.2-4.918A1.326,1.326,0,0,0,41.812,9.136Z"
                transform="translate(0 -0.024)"
                fill="#f0ebea"
              />
            </g>
          </g>
        </g>
        <g id="Layer_1_1_-i11" transform="translate(883.354 229.833)">
          <g id="Group_7780" data-name="Group 7780">
            <path
              id="Path_2222"
              data-name="Path 2222"
              d="M3.123,50.861,0,50.006l1.92-7.134A10.705,10.705,0,0,1,15,35.344l6.409,1.775a7.323,7.323,0,0,0,9.077-5.194l1.757-6.476a10.69,10.69,0,0,1,13.079-7.528L51.731,19.7A7.323,7.323,0,0,0,60.809,14.5l1.757-6.476A10.69,10.69,0,0,1,75.645.5l6.442,1.775-.846,3.156L74.8,3.654a7.453,7.453,0,0,0-9.11,5.227l-1.757,6.476a10.593,10.593,0,0,1-13.079,7.528L44.443,21.11a7.453,7.453,0,0,0-9.11,5.227l-1.757,6.476A10.593,10.593,0,0,1,20.5,40.341L14.153,38.5a7.453,7.453,0,0,0-9.11,5.227Z"
              transform="translate(0 -0.124)"
              fill="#f0ebea"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
